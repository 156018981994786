import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Header from '../../Components/Header'
import MainContainer from '../../Components/MainContainer'
import AboutContent from './Components/AboutContent'
import AboutContentMobile from './Components/AboutContentMobile'

export default function AboutScreen() {

    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 756)


    useEffect(() => {
        setIsBigScreen(window.innerWidth > 756)
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const handleMediaQueryChange = (matches) => {
        setIsBigScreen(window.innerWidth > 756)
    }

    return (
        <>
            <Header />
            <div
                style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 30,
                    marginTop: 30
                }}>
                Hakkımda
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>

                {
                    isBigScreen

                        ?
                        <AboutContent /> : <AboutContentMobile
                            isBigScreen={isBigScreen} />
                }

            </div>
        </>
    )
}
