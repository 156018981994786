import React, { useContext } from 'react'
import { WindowSizeContext } from '../../../App'
import TherapyItem from './TherapyItem'

export default function TherapyItemContainer(props) {
    const { items, first, isBigScreen } = props
    // const isBigScreen = useContext(WindowSizeContext)

    const renderContainer = (props) => {

        return <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: first || isBigScreen ? 0 : 50 }}>
            {renderItems(items)}
        </div>

    }
    const renderItems = (items) => {
        return items.map((item) => {
            return <TherapyItem title={item.title} icon={item.icon} />
        })
    }
    return renderContainer()
}
