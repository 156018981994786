import React from 'react'
import { MenuNames, MenuRoutes } from '../Constants/ConstantValues'
import FooterContact from './FooterContact'
import FooterMenuItem from './FooterMenuItem'
import './Styles/Footer.css'

const menu = ["Anasayfa",
    "Hakkımda",
    "Çalışma Alanları",
    "Blog",
    "İletişim"]

export default function FooterResponsive() {

    const renderMenuItems = () => {
        return MenuNames.map((title, index) => {
            return <FooterMenuItem route={MenuRoutes[index]} index={index} title={title} />
        })
    }

    return (
        <>
            <div className='footerContainerResponsive'>

                <div className='footerLogoContainerResponsive'>
                    <div className='footerImageContainer'>
                        <div style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            marginTop: 25,
                            boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
                        }}>
                            <img src={require(`./../Assets/Images/logo4.png`)}
                                className='footerImage' />
                        </div>
                    </div>
                </div>
                <div className='footerHr' style={{
                    width: '50%',
                    height: 1,
                    // backgroundColor: 'black',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    // marginLeft: '25%',
                    marginBottom: 15,
                    marginLeft: 15,
                }} />
                <div style={{
                    width: '100%',
                    display: 'flex'
                }}>
                    <div style={{
                        width: '50%'
                    }}>

                        <div id='footerMenuContainerResponsive'>
                            {renderMenuItems()}
                        </div>
                    </div>
                    <div style={{
                        width: '50%'
                    }}>

                        <FooterContact />

                    </div>
                </div>





            </div>
            <div style={{
                width: '100%',
                height: 50,
                backgroundColor: 'rgba(255,255,255,0.15)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 12
            }}>
                @2022 Zeynep Ekşioğlu - Tüm Hakları Saklıdır.
            </div>
        </>
    )
}
