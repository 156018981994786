import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function ShowMoreButton(props) {
    const { mobile, id, title, blogId } = props
    let navigate = useNavigate();

    const buttonClick = () => {
        navigate(`/blog/id=${blogId}?title=${title}`)
    }

    return (
        <div style={{
            display: mobile ? 'flex' : null,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20
        }}>
            <div onClick={buttonClick} id='blogMoreButton' style={{

            }}>
                Devamını Oku
            </div>
        </div>
    )
}

ShowMoreButton.defaultProps = {
    mobile: false
}