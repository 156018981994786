import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import HomeScreen from './Sections/Home/HomeScreen';
import Footer from './Components/Footer';

import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route
} from "react-router-dom";
import AboutScreen from './Sections/AboutMe/AboutScreen';
import ProjectAreasScreen from './Sections/ProjectAreas/ProjectAreasScreen';
import BlogScreen from './Sections/Blog/BlogScreen';
import ContactScreen from './Sections/Contact/ContactScreen';
import MoveToTop from './Components/MoveToTop';
import BlogDetailScreen from './Sections/Blog/BlogDetailScreen';
import { createContext, useEffect, useState } from 'react';

export const WindowSizeContext = createContext()

function App() {
  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 756)


  useEffect(() => {
    window.addEventListener('resize', handleMediaQueryChange)
  }, [])
  useEffect(() => {
  }, [isBigScreen])
  const handleMediaQueryChange = (matches) => {
    setIsBigScreen(window.innerWidth > 756)
  }

  return (
    <Router>
      <MoveToTop />
      <Routes>
        <Route exact path='/' element={
          <WindowSizeContext.Provider value={isBigScreen}>
            <HomeScreen />
          </WindowSizeContext.Provider>} />
        <Route path='/hakkimda' element={<AboutScreen />} />
        <Route path='/calisma_alanlari' element={<WindowSizeContext.Provider value={isBigScreen}>
          <ProjectAreasScreen />
        </WindowSizeContext.Provider>} />
        <Route path='/blog' element={<BlogScreen />} />
        <Route path='/blog/:blogId' element={<WindowSizeContext.Provider value={isBigScreen}>
          <BlogDetailScreen />
        </WindowSizeContext.Provider>} />
        <Route path='/iletisim' element={<ContactScreen />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
