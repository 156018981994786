import React from 'react'

export default function HrComponent() {
    return (
        <div className='footerHr' style={{
            width: '50%',
            height: 1,
            // backgroundColor: 'black',
            alignSelf: 'center',
            justifySelf: 'center',
            // marginLeft: '25%',
            marginBottom: 15,
            marginLeft: 15,
        }} />
    )
}
