import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import MainContainer from '../../Components/MainContainer'
import { blogs } from '../../Constants/Blogs'
import Blog from './Components/Blog'
import BlogMobile from './Components/BlogMobile'

const blogMock = [
    { deneme: "" },
    { deneme: "" },
    { deneme: "" },
    { deneme: "" },
    { deneme: "" },
]
export default function BlogScreen() {

    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 970)

    useEffect(() => {
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const handleMediaQueryChange = (matches) => {
        setIsBigScreen(window.innerWidth > 970)
    }

    const renderBlog = () => {
        return blogs.blogs.map((element, index) => {
            return isBigScreen ?
                <Blog
                    id={element.id}
                    title={element.title}
                    content={element.content}
                    image={element.image}
                    index={index} /> :
                <BlogMobile
                    id={element.id}
                    title={element.title}
                    content={element.content}
                    image={element.image}
                    index={index} />
        })
    }

    return (
        <>
            <Header />
            <div style={{
                textAlign: 'center',
                marginTop: 30,
                fontSize: 30,
                fontWeight: 'bold',

            }}>Bloglar</div>
            {renderBlog()}
        </>
    )

}
