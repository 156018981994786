import React from 'react'
import MainContainer from '../../../Components/MainContainer'
import '../BlogScreenStyles.css'
import ShowMoreButton from './ShowMoreButton'
import parse from 'html-react-parser';

export default function Blog(props) {
    const { index, title, content, image, id } = props

    const renderContent = () => {
        var textArray = content[0].split(" ")
        textArray = textArray.slice(0, 50)
        return parse(textArray.join(" ") + "...")
    }

    const renderBlogContent = () => {
        if (index % 2 === 0) {
            return (
                <>
                    <div style={{
                        flex: 0.2,
                        alignItems: 'center'
                    }}>
                        <div id='blogImage' style={{
                            width: 250,
                            height: 250,
                            // backgroundColor: 'red',
                            borderRadius: 10,
                            boxShadow: "-5px 5px 12px rgba(0,0,0,0.2)",
                            overflow: 'hidden'
                        }}>
                            <img style={{
                                width: '100%', height: '100%'
                            }} src={image} />
                        </div>
                    </div>
                    <div style={{
                        flex: 0.8,
                        marginLeft: 20,
                    }}>
                        <div style={{
                            fontSize: 20,
                            fontWeight: 'bold'
                        }}>{title}</div>
                        <div id='blogContent' style={{
                            marginTop: 10,
                            lineHeight: '1.7em'
                        }}>{renderContent()}  </div>
                        <ShowMoreButton blogId={id} title={title} />

                    </div>
                </>
            )
        } else {
            return (
                <>

                    <div style={{
                        flex: 0.8,
                    }}>
                        <div style={{
                            fontSize: 20,
                            fontWeight: 'bold'
                        }}>{title}</div>
                        <div id='blogContent' style={{
                            marginTop: 10,
                            lineHeight: '1.7em'
                        }}>{renderContent()}</div>
                        <ShowMoreButton blogId={id} title={title} />

                    </div>
                    <div style={{
                        flex: 0.2,
                        alignItems: 'center',
                        marginLeft: 20,
                    }}>
                        <div id='blogImageRight' style={{
                            width: 250,
                            height: 250,
                            // backgroundColor: 'red',
                            borderRadius: 10,
                            boxShadow: "-5px 5px 12px rgba(0,0,0,0.2)",
                            overflow: 'hidden'
                        }}>
                            <img style={{
                                width: '100%', height: '100%'
                            }} src={image} />
                        </div>
                    </div>
                </>
            )
        }
    }

    return (

        <MainContainer containerStyle={
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 30,
                width: '100%'
            }
        } style={{
            width: '100%',
            marginRight: 50,
            marginLeft: 50,
            maxWidth: 800,
            padding: 50,

        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width:'100%'
            }}>
                {renderBlogContent()}
            </div>
        </MainContainer>
    )
}
