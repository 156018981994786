import React from 'react'

export default function SolutionInfo() {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            marginTop: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 0px 10px 0px',
            // backgroundColor: 'rgba(147, 231, 250, 0.9)',
            position: 'relative',
        }}>
            <img
                src={require('../../../Assets/Images/solution_info_background4.jpg')}
                style={{
                    width: '100%',
                    position: 'absolute',
                    height: '100%',
                    zIndex: -1,
                    resize: ''
                }} />
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                // backgroundColor:'red'
            }}>
                {/* <div style={{
                    width: '50%',
                    height: '100%',

                }}>

                </div> */}
                <div style={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',

                    marginTop: 20,
                    marginBottom: 20,
                    alignItems: 'center',

                }}>
                    <div style={{
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        borderRadius: 25,
                        padding: 20,
                        minHeight: 200,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",

                    }}>
                        <div style={{
                            fontSize: 27,
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}>
                            Kognitif Terapi
                        </div>
                        <div style={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            lineHeight: 1.4,
                            fontSize: 17
                        }}>
                            Kognitif terapi, sayısız psikolojik sıkıntının tedavisinde oldukça etkili olduğu bilimsel olarak kanıtlanmış bir terapi yöntemidir. Kognitif Terapi; düşüncelerinizin, duygularınızın, bedensel belirtilerinizin ve davranışlarınızın birbiriyle bağlantılı olduğu ve bu bağlantının sizi bir kısır döngüye hapsettiği prensibine dayanır. Kognitif Terapide odak; mevcut sıkıntınızı oluşturan bu kısır döngüden çıkmak için bu bağlantıyı nasıl değiştirebileceğinizdir.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
