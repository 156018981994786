import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Header from '../../Components/Header'
import HrComponent from '../../Components/HrComponent'
import MainContainer from '../../Components/MainContainer'

export default function ContactScreen(props) {


    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 756)

    useEffect(() => {
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const handleMediaQueryChange = (matches) => {
        setIsBigScreen(window.innerWidth > 756)
    }


    const resizeForMobile = () => {
        return isMobile ?
            isBigScreen ? '100%' : '60%'
            : '100%'
    }

    const renderContactInfo = () => {
        if (!isBigScreen) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: 30, height: 30 }} src={require(`../../Assets/Images/telephone-call.png`)}
                            className='footerLogo' />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>+90 546 558 39 99</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <img style={{ width: 30, height: 30 }} src={require(`../../Assets/Images/email (1).png`)}
                            className='footerLogo' />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>psk.zeynepeksioglu@gmail.com</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                }}>
                    <div style={{ display: 'flex', flex: 0.5, alignItems: 'center', flexDirection: 'column' }}>
                        <img style={{ width: 30, height: 30 }} src={require(`../../Assets/Images/telephone-call.png`)}
                            className='footerLogo' />
                        <span style={{ marginTop: 10, marginLeft: 10, fontSize: 18 }}>+90 546 558 39 99</span>
                    </div>
                    <div style={{ display: 'flex', flex: 0.5, alignItems: 'center', flexDirection: 'column' }}>
                        <img style={{ width: 30, height: 30 }} src={require(`../../Assets/Images/email (1).png`)}
                            className='footerLogo' />
                        <span style={{ marginTop: 10, marginLeft: 10, fontSize: 18 }}>psk.zeynepeksioglu@gmail.com</span>
                    </div>
                </div>
            )
        }
    }
    return (
        <>
            <Header />

            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>

                <MainContainer containerStyle={
                    {
                        display: 'flex',
                        marginTop: 30,
                        width: resizeForMobile(),
                        justifyContent: 'center'
                    }
                } style={{
                    width: '100%',
                    marginRight: 50,
                    marginLeft: 50,
                    maxWidth: 800,
                    padding: 50,
                }}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>

                        <div style={{
                            marginBottom: 20,
                            textAlign: 'center',
                            fontSize: 25,
                            fontWeight: 'bold'
                        }}>İletişim Bilgilerim</div>
                        <HrComponent />
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: 20
                        }}>



                            {renderContactInfo()}



                        </div>
                        <div style={{
                            marginTop: 50,
                            textAlign: 'center',
                            fontSize: 25,
                            marginBottom: 20,
                            fontWeight: 'bold'
                        }}>Sosyal Medya Hesaplarım</div>

                        <HrComponent />

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            marginTop: 20
                        }}>

                            {/* <a href="" target="_blank" style={{ width: 40, height: 40 }}>
                                <img src={require(`../../Assets/Images/instagram.png`)}
                                    className='footerLogo' />
                            </a> */}
                            <a onClick={() => {
                                window.open('https://www.linkedin.com/in/zeynepeksioglu/')
                            }} target="_blank" className='contactSocialButton' style={{ width: 40, height: 40, marginLeft: 10 }}>
                                <img src={require(`../../Assets/Images/linkedin.png`)}
                                    className='footerLogo' />
                            </a>
                        </div>
                    </div>
                </MainContainer>

            </div>

        </>
    )
}
