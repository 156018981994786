import React from 'react'
import './Styles/Title.css'

export default function Title() {
    return (
        <>
            {/* <div className='deneme'> */}
            <div className='headerWorkTitle'>Uzman Klinik Psikolog</div>
            <div className='headerWorkTitle headerNameTitle'>Zeynep Ekşioğlu</div>
            {/* </div> */}
        </>
    )
}
