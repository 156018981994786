import React from 'react'
import { isMobile } from 'react-device-detect'
import MainContainer from '../../../Components/MainContainer'
import { aboutText, educations } from '../../../Constants/TextFiles'
import TakingEducations from './TakingEducations'
import GivingEducations from './GivingEducations'
import './Styles/AboutContent.css'

export default function AboutContent(props) {
    const { isBigScreen } = props

    const splitAboutGeneral = () => {
        var textArray = aboutText.split("/n")
        return textArray.map((element, index) => {
            return (
                <p>
                    {element}
                </p>
            )
        })
    }

    const resizeForMobile = () => {
        return isMobile ?
            isBigScreen ? '100%' : '60%'
            : '100%'
    }

    return (
        <>
            <MainContainer
                containerStyle={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 30,
                        width: resizeForMobile()

                    }
                } style={{
                    width: '100%',
                    marginRight: 50,
                    marginLeft: 50,
                    // height: 200,
                    maxWidth: 800,
                    padding: 50,

                }}>
                <div
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: 27,
                    }}>
                    Uzman Klinik Psikolog Zeynep Ekşioğlu
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <div
                        style={{
                            // textAlign: 'center',
                            marginTop: 10,
                            // marginLeft: 20,
                            lineHeight: '1.7em'
                        }}>
                        <div style={{ textAlign: 'justify' }}>
                            {splitAboutGeneral()}
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                        // backgroundColor:'red'
                    }}>
                        <MainContainer id='aboutAvatarContainer' style={{
                            width: 300,
                            height: 400,
                            marginTop: 25,
                            overflow: 'hidden',
                        }}>
                            <img id='aboutAvatar' src={require('../../../Assets/Images/avatar-3.png')} />
                        </MainContainer>




                    </div>


                    <TakingEducations />
                    <GivingEducations />
                </div>
            </MainContainer>
        </>
    )
}
