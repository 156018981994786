import React from 'react'
import { SSSData } from '../../../Constants/TextFiles'
import AccordionItem from './AccordionItem'

export default function SSS() {

    const renderQuestions = () => {
        return SSSData.map((question) => {
            return <AccordionItem title={question.title}
                icon="psychology.png"
                description={question.description} />
        })
    }
    return (
        <div style={{
            marginTop: 30,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
        }}>

            <div style={{
                textAlign: 'center',
                fontSize: 32,
                fontWeight: 'bold',
                marginBottom: 25,
                height: '100%'

            }}>
                Sıkça Sorulan Sorular
            </div>
            <div style={{
                width: '80%',
                height: '100%'
            }}>
                {renderQuestions()}
            </div>
        </div>
    )
}
