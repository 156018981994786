/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './Styles/SocialMediaButtons.css'

const social = [
    { title: "gmail", icon: "gmail.png", url: "mailto:psk.zeynepeksioglu@gmail.com" },
    // { title: "instagram", icon: "instagram.png", url: "https://www.google.com" },
    { title: "linkedin", icon: "linkedin.png", url: "https://www.linkedin.com/in/zeynepeksioglu/" }
]

export default function SocialMediaButtons() {

    const renderSocialButtons = () => {
        return social.map((element, index) => {
            // return <a target="_blank" href={element.url} className={index !== 1 ? "socialButton sideButton" : " socialButton middleButton"}>
            return <a target="_blank"
                onClick={() => {
                    // window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')
                    window.open(element?.url)
                }}  className={"socialButton sideButton"} >
                <img alt='' src={require(`./../Assets/Images/${element.icon}`)}
                    style={{ width: '50%', height: '50%' }} />
            </a >
        })
    }

    return (
        <div id='socialContainer'>
            {renderSocialButtons()}
        </div>
    )
}
