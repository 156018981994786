import React from 'react'
import MainContainer from '../../../Components/MainContainer'
import { aboutText } from '../../../Constants/TextFiles'
import TakingEducations from './TakingEducations'
import './Styles/AboutContent.css'
import GivingEducations from './GivingEducations'

export default function AboutContent() {

    const splitAboutGeneral = () => {
        var textArray = aboutText.split("/n")
        return textArray.map((element, index) => {
            return (
                <p>
                    {element}
                </p>
            )
        })
    }

    return (

        <MainContainer
            containerStyle={
                {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 30,
                    width: '100%'

                }
            } style={{
                width: '100%',
                marginRight: 50,
                marginLeft: 50,
                // height: 200,
                maxWidth: 800,
                padding: 50,

            }}>

            <div
                style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 27,
                }}>
                Uzman Klinik Psikolog Zeynep Ekşioğlu
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <div style={{
                    flex: 0.4,
                    // display:'flex',
                    // backgroundColor:'red'
                }}>
                    <MainContainer id='aboutAvatarContainer' style={{
                        width: 300,
                        height: 400,
                        marginTop: 25,
                        overflow: 'hidden',
                    }}>
                        <img id='aboutAvatar' src={require('../../../Assets/Images/avatar-3.png')} />
                    </MainContainer>

                    <TakingEducations />

                </div>

                <div
                    style={{
                        flex: 0.6,
                        // textAlign: 'center',
                        marginTop: 10,
                        marginLeft: 20,
                        lineHeight: '1.7em'
                    }}>

                    <div style={{textAlign:'justify'}}>
                        {splitAboutGeneral()}
                    </div>
                    <GivingEducations />

                </div>
            </div>
        </MainContainer>

    )
}
