import React from 'react'
import './Styles/Footer.css'

export default function FooterMenuItem(props) {
    const { title, index, route } = props
    return (
        <a href={route} className={index === 0 ? 'footerMenuItemContainer' : 'footerMenuItemContainer footerMargin'}>
            <div className='footerMenuIcon'>
                <img src={require(`./../Assets/Images/click.png`)}
                    className='footerImage' />
            </div>
            <div className='footerMenuText'>
                {title}
            </div>
        </a>
    )
}
