import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import MainContainer from '../../Components/MainContainer'
import MoveToTop from '../../Components/MoveToTop'
import MainContent from './Components/MainContent'
import PsychologyTypes from './Components/PsychologyTypes'
import PsychologyTypesMobile from './Components/PsychologyTypesMobile'
import SolutionInfo from './Components/SolutionInfo'
import SSS from './Components/SSS'
import TherapyTypes from './Components/TherapyTypes'

const psyTypes = [
    { title: "Terapötik İlişki", description: "Arkadaşlık ilişkisinden farklı olarak kurulan profesyonel ilişkide amaç danışanda faydalı bir değişiklik yaratmayı hedeflemektir.", icon: 'type1.png' },
    { title: "Sıkıntıların Listelenmesi", description: "Mücadele ettiğiniz sıkıntılar konuşulur ve değerlendirilir. Amaç, sıkıntıların öncelik sırasına konmasıdır.", icon: 'type2.png' },
    { title: "Hedeflerin Belirlenmesi", description: "Süreç boyunca izlenecek yol için gerçekçi ve ulaşılabilir hedefler iş birliği içinde belirlenerek soyut terapi süreci somutlaştırılır.", icon: 'type3.png' },
    { title: "Terapi Süreci ve Sonlandırma", description: "Konulan hedefler doğrultusunda uygulamalar yapılır. Amaç, süreç boyunca değişimin başlaması ve sonunda iyileşmenin kalıcı olmasıdır.", icon: 'type5.png' }
]
export default function HomeScreen() {

    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 970)

    useEffect(() => {
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const handleMediaQueryChange = (matches) => {
        setIsBigScreen(window.innerWidth > 970)
    }

    const renderTypes = () => {
        return psyTypes.map((element, index) => {
            return isBigScreen ?
                <PsychologyTypes
                    title={element.title}
                    description={element.description}
                    icon={element.icon} />
                :
                <PsychologyTypesMobile
                    title={element.title}
                    description={element.description}
                    icon={element.icon} />
        })
    }

    return (
        <>
            <Header />

            <TherapyTypes />
            <SolutionInfo />

            {/* <MainContent /> */}
            <div style={{
                marginTop: 30,
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 32,
                marginBottom: 25
            }}>
                Terapi Süreci Nasıl İşler?
            </div>
            {
                isBigScreen ?
                    <div style={{
                        display: 'flex',
                    }}>
                        {renderTypes()}
                    </div> :
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {renderTypes()}
                    </div>

            }


            <SSS pageTitle="Sıkça Sorulan Sorular" />
        </>
    )
}
