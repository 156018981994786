import React from 'react'
import { isMobile } from 'react-device-detect'

export default function MainContainer(props) {
    const { children, style, containerStyle, id } = props
    return (
        <div id={id} style={{
            ...containerStyle
        }}>

            <div style={{

                width:'100%',
                backgroundColor: 'white',
                borderRadius: 10,
                boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
                justifyContent: 'center',
                alignItems: isMobile ? 'center' : null,
                display: 'flex',
                flexDirection: 'column',
                ...style,

            }}>
                {children}
            </div>
        </div>
    )
}
