import React, { useRef, useState } from 'react'
import './Styles/SSSStyles.css'
import AnimateHeight from 'react-animate-height';

export default function AccordionItem(props) {

    const { description, title, titleMode, icon } = props

    const questionWrapper = useRef()
    const detailsWrapper = useRef()
    const [height, setHeight] = useState(0)

    const animationStyle = {
        transform: height ? 'rotate(-180deg)' : '',
        transition: 'transform 500ms ease',
    }

    const toggleQuestion = () => {
        setHeight(height === 0 ? 'auto' : 0)
    }

    return (
        <div
            style={{
                height: '100%',
                overflow: 'hidden',
                marginTop: 1
            }}>

            <div
                className='questionItem'
                ref={questionWrapper}
                onClick={() => toggleQuestion()}>
                <div style={{
                    width: 30, height: 30
                }}>
                    <img src={require(`../../../Assets/Images/${icon}`)}
                        style={{
                            width: '100%', height: '100%'
                        }}
                    />
                </div>
                <div style={{
                    marginLeft: 10,
                    width: '90%',
                    lineHeight: 1.4,
                    fontSize: 18,
                    textAlign: titleMode
                }}>
                    {title}
                </div>
                <div className='accordion_right_icon'>
                    <img src={require(`../../../Assets/Images/arrow.png`)}
                        style={animationStyle}
                    />
                </div>
            </div>
            <AnimateHeight
                duration={500}
                height={height}
                ref={detailsWrapper} className='questionDetails'>
                <div style={{
                    margin: 20,
                    lineHeight: 1.4,
                    fontSize: 17
                }}>
                    {description}
                </div>
            </AnimateHeight>
        </div>
    )
}

AccordionItem.defaultProps = {
    titleMode: null
}