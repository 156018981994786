import React from 'react'

export default function FooterContact() {

  const menuItems = [
    { title: "gmail", icon: "gmail.png", url: "mailto:psk.zeynepeksioglu@gmail.com" },
    // { title: "instagram", icon: "instagram.png", url: "https://www.google.com" },
    { title: "linkedin", icon: "linkedin.png", url: "https://www.linkedin.com/in/zeynepeksioglu/" }
  ]


  const _renderContactItems = () => {
    return menuItems.map((value) => {
      return (
        <a target="_blank"
          onClick={() => window.open(value?.url)} className='footerSocialIconContainer'>
          <img src={require(`./../Assets/Images/${value?.title}.png`)}
            className='footerLogo' />
        </a>
      )
    });
  }

  return (
    <div id='footerContactContainer'>
      <div className='footerInfoContainer'>
        <div className='footerContactLogoContainer'>
          <img src={require(`./../Assets/Images/telephone-call.png`)}
            className='footerLogo' />
        </div>
        <div className='footerInfoText'>
          +90 546 558 39 99
        </div>
      </div>
      <div id='footerSocialContainer'>
        {_renderContactItems()}
      </div>
    </div>
  )
}
