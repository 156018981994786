import React from 'react'
import { Link } from 'react-router-dom'

export default function NavbarResponsiveItem(props) {
    const { title, routes } = props

    return (
        <Link style={{
            width: '100%',
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            // color: '#286373',
            color: 'black'
            // marginLeft:25
        }} to={routes}>

            {/* <div style={{ width: 15, height: 15 }}>
                <img src={require(`../Assets/Images/mental-health.png`)}
                    style={{ width: '100%', height: '100%' }} />
            </div> */}
            <span style={{
                fontSize: 27,
                marginLeft: 10,
                marginRight: 10
            }}>
                {title}
            </span>
            {/* <div style={{ width: 15, height: 15 }}>
                <img src={require(`../Assets/Images/mental-health.png`)}
                    style={{ width: '100%', height: '100%' }} />
            </div> */}

        </Link>
    )
}
