import React from 'react'
import SocialMediaButtons from './SocialMediaButtons'
import './Styles/Avatar.css'
import Title from './Title'

export default function Avatar() {
    return (
        <div id='avatarContainer'>
            <div id='subContainer'>
                <SocialMediaButtons />
                <img
                    id='avatar' src={require('../Assets/Images/avatar-3.png')} />
            </div>
        </div>
    )
}
