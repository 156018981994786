import React from 'react'

export default function TherapyItem(props) {
    const { title, icon } = props

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '50%'
        }}>
            <div style={{
                width: 75,
                height: 75,
                backgroundColor: 'white',
                borderRadius: 75,
                boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img src={require(`../../../Assets/Images/${icon}`)}
                    style={{ width: '70%', height: '70%' }} />
            </div>
            <div style={{ marginTop: 15, textAlign: 'center' }}>
                {title}
            </div>
        </div>
    )
}
