export const aboutText = `
Zeynep Ekşioğlu, Özyeğin Üniversitesi Psikoloji bölümünden onur derecesi ile mezun olmuştur. Lisans eğitimi alırken Dr. Gizem Arıkan’ın TÜBİTAK destekli Güvenli Çember Ebeveyn Eğitim Programı Projesi’nde 1-3 yaş arası çocukların anneleri ile bağlanma ilişkisinin incelenmesinde aktif rol almıştır. Çocuklarla ilgili çalışmalarını desteklemek amacıyla MEVA Psikolojik Danışmanlık ve Rehberlik Merkezi’nde Uzman Klinik Psikolog Sergül Oğur Çaydamlı eşliğinde ailelerinden izin alınmış çocukların seanslarına gözlemci olarak katılmıştır. Ayrıca, AÇEV’in Hayat Dolu Buluşmalar ve Genç Kızlar Güçleniyor projelerine aktif gönüllü destek vermiştir.

/nEğitimine İstanbul Okan Üniversitesi’nde Klinik Psikoloji dalında yüksek lisans ile devam eden Zeynep Ekşioğlu, Kognitif Terapi (bilinen adıyla Bilişsel Davranışçı Terapi) eğitimini Psikolog Doktor Burcu Sevim’den almıştır. Daha sonra Uzman Klinik Psikolog Neşe Uluşan süpervizörlüğünde Kognitif Terapi ekolünde danışan görmüştür.
 
/nBu dönemde Dr. Emel Stroup’un CBTiSTANBUL bünyesi altında verdiği “Beck Yönelimli Kognitif Terapi ve Klinik Becerileri Geliştirme Programı’na kabul edilerek bir sene boyunca programa Kabul edilen diğer iki meslektaşı ile birlikte hem Dr. Emel Stroup’un asistanlığını yapmış hem de programın koordinatörlüğünü üstlenmiştir. Bir yıllık bu süreç zarfında periyodik olarak Kognitif Terapi ve klinik alanda Dr. Emel Stroup’un ders ve eğitimlerine katılarak Kognitif Terapi becerilerini öğrenmiş ve bu becerilerini geliştirerek bilimsel donanımını artırmıştır. Dr. Emel Stroup tarafından verilen tüm workshopların hazırlık sürecine dahil olmuş, tüm Kognitif Terapi workshoplarına katılmış ve Kognitif Terapi workshoplarında katılımcıları bilgilendirmek amacıyla bilimsel araştırmaları temel alıp programda öğrenilen bilgileri entegre ederek eğitimler vermiştir. CBTiSTANBUL’un sosyal sorumluluk projesi kapsamında “Beck Yönelimli Kognitif Terapi ve Klinik Becerileri Geliştirme Programı’nda” yer alan diğer iki meslektaşı ile birlikte psikoloji öğrencilerini ve psikologları bilgilendirmek amacıyla bir Webinar düzenlemiş ve “Covid-19 Sürecinde Klinisyenlerin Karşılaşabileceği Patolojiler ve Riskli Durumlar” konulu Webinarı vermiştir.
 
/nYüksek lisans eğitimini Dr. Öğretim Üyesi Ayşe Özlem Mestçioğlu danışmanlığında “Üniversite Mezunu İşsiz Bireylerde Psikolojik İyi Oluş, Umutsuzluk Seviyesi ve Algılanan Sosyal Destek Arasındaki İlişkinin İncelenmesi” konulu tezi ile tamamlayarak “Uzman Klinik Psikolog” unvanını almıştır.
`


export const SSSData = [
    { title: "Terapi süreci bana nasıl yardımcı olabilir?", description: "Terapi, düşünce kalıplarınızı ve davranışlarınızı değiştirmenizde, zorlukların üstesinden gelmenizde, kim olduğunuzu keşfetmenizde ve bazı şeyleri neden yaptığınızın farkına varmanızda sizlere yardımcı olabilir." },
    { title: "Gizlilik nasıl korunur?", description: "Başvurduğunuz andan itibaren gizliliğinizi korumak psikoloğunuzun en temel ilkelerinden biridir. Paylaştığınız hiçbir bilgi siz yazılı izin vermediğiniz takdirde hiç kimse ile paylaşılamaz. Ancak, kendinize ya da başkasına zarar vereceğiniz bir durumda psikoloğunuz gizliliği bozmakla yükümlüdür." },
    { title: "Terapistiniz size “çare” olabilir mi?", description: "Terapi mevcut sıkıntılarınızla baş etmeyi öğreneceğiniz bir süreçtir. Ancak, sıkıntınız ne olursa olsun, terapi her zaman için hızlı bir çözüm değildir. Terapi süreci zorlu olabilir ve yavaş ilerleyebilir. Bu nedenle, size “çare olacağına” dair sözler veren bir psikolog gerçekçi olmayan fikirlere sahip olabilir. Sizi iyileştirecek olan psikoloğunuz değil, sürecin kendisidir." },
    { title: "Terapinin etkililiği nelere bağlıdır?", description: "Terapinin sizin için ne kadar etkili olduğu; size, ihtiyaçlarınıza, hedeflerinize, seanslar sırasında ve seanslar arasında ne kadar çalıştığınıza bağlı olarak değişkenlik gösterecektir." },
    { title: "Süreç kaç seans sürer?", description: "Sürecinizin kaç seans süreceğini önceden belirlemek ve kalıba sığdırmak mümkün değildir. Psikoloğunuzla birlikte sizin ihtiyaçlarınızı baz alarak iş birliği içinde belirlediğiniz hedefleriniz süreç içindeki somut yol haritanızın oluşmasını sağlayacaktır." },
    { title: "İstediğim zaman terapiyi sonlandırabilir miyim?", description: "İstediğiniz an terapiyi sonlandırma hakkına sahipsiniz. Ayrıca, mevcut psikoloğunuzla rahatça konuşamıyorsanız, güvende hissetmiyorsanız, psikoloğunuz tarafından anlaşıldığınızı hissetmiyorsanız ve hedefleriniz doğrultusunda ilerlemiyor gibi hissediyorsanız psikoloğunuzu değiştirebilirsiniz." }
]

export const educations = {
    "educations": {
        "gettingEducations": [
            {
                "educationName": "Kognitif Terapi Workshop Sertifika Programı",
                "time": "75 saat",
                "teachers": [
                    "Dr. Emel Stroup"
                ],
                "content": [
                    "Beck Yönelimli Kognitif Terapi Becerileri ve Klinik İlk Görüşme",
                    "Beck Yönelimli Kognitif Terapi İlkeleri ve Depresyon Tedavisinde Uygulanması",
                    "Beck Yönelimli Kognitif Terapinin Anksiyete Tedavisinde Uygulanması",
                    "Beck Yönelimli Kognitif Terapinin OKB Tedavisinde Uygulanması",
                    "OKB’de ERP’nin (Maruz Bırakarak Tepki Önleme) Uygulanması",
                    "Travma Sonrası Stres Bozukluğunun Beck Yönelimli Kognitif Terapide Klinik Pratik Uygulamalar ile Tedavisi",
                ]
            },
            {
                "educationName": "Beck Enstitüsü – Anksiyete Bozukluklarında CBT Workshop",
                "time": "16 saat",
                "teachers": [
                    "Dr. Emel Stroup"
                ],
                "content": []
            },
            {
                "educationName": "Çocuk ve Ergenlerde BDT Teori Eğitimi",
                "time": "80 saat",
                "teachers": [
                    "Doç. Dr. Vahdet Görmez",
                    "Prof. Dr. Hakan Türkçapar",
                    "Prof. Dr. Kadir Özdel",
                    "Uzm. Dr. Süleyman Çakıroğlu ",
                    "Uzm. Dr. Hakan Öğütlü"
                ],
                "content": [
                    "Bilişsel Davranışçı Terapilerde Temel İlkeler",
                    "Çocuk ve Ergenlerde BDT’nin Temel Prensipleri",
                    "Özgül Fobilerin Bilişsel Modeli ve Terapi Prensipleri",
                    "Yaygın Anksiyete Bozukluğu Bilişsel Modeli ve Terapi Prensipleri",
                    "Panik Bozukluk ve Agorafobi Bilişsel Modeli ve Terapi Prensipleri",
                    "Ayrılma Anksiyetesi Bozukluğu’nun Bilişsel Modeli ve Terapi Prensipleri",
                    "Obsesif Kompulsif Bozukluk (OKB) Tedavisinde BDT ve Maruz Bırakma/Tepki Önleme Çalışmaları",
                    "Çocuk ve Ergenlerde Depresyon İle Çalışma",
                    "Sosyal Anksiyete Bozukluğu Bilişsel Modeli ve Terapi Prensipleri",
                    "Sınav Anksiyetesi’nin Bilişsel Modeli ve Terapi Prensipleri",
                    "Yeme Bozukluklarının Tedavisine Yaklaşımda Bilişsel Dvranışçı Terapi",
                    "Travma Ve Strese Bağlı Sorunların Tedavisinde Bilişsel Davranışçı Terapi",
                ]
            },
            {
                "educationName": "Aile Danışmanlığı",
                "time": "450 saat",
                "teachers": [
                    "Yıldız Teknik Üniversitesi Sürekli Eğitim Akademisi"
                ],
                "content": []
            },
        ],
        "givingEducations": [
            {
                "educationName": "Covid-19 Sürecinde Klinisyenlerin Karşılaşabileceği Patolojiler ve Riskli Durumlar Webinarı’nda Online Terapi sürecine geçişte katılıcıların karşılaşabileceği riskli durumlar ve Covid-19 pandemisi sürecinde klinisyenlerin karşılaşabileceği patolojiler üzerine eğitim vermek",
            },
            {
                "educationName": "Beck Yönelimli Kognitif Terapi İlkeleri ve Depresyon Tedavisinde Uygulanması Workshop’unda “Depresyon Tedavisinde Kognitif Terapi ve İlaç Tedavisinin Etkinliğinin Karşılaştırılması” araştırmasını baz alarak ve ilgili diğer makalelerle entegre ederek Kognitif Terapi’nin nüks önleme üzerindeki etkinliğini hakkında eğitim vermek",
            },
            {
                "educationName": "Beck Yönelimli Kognitif Terapinin Anksiyete Tedavisinde Uygulanması Workshop’unda “Terapistin Dikkat Etmesi Gereken Tehlike İşaretleri” bölümünde; hastaların kullandığı güvenlik davranışları hakkında katılımcılara eğitim vermek ve klinisyenlerin bilerek ya da bilmeyerek hastalarına güvenlik davranışları kullandırtmasının ya da öğretmesinin yaratacağı zararı/riskleri örnekler üzerinden işleyerek katılımcılara fark ettirmek",
            },
            {
                "educationName": "Beck Yönelimli Kognitif Terapinin Anksiyete Tedavisinde Uygulanması Workshop’unda “Terapistin Dikkat Etmesi Gereken Tehlike İşaretleri” bölümünde; hastaların kullandığı güvenlik davranışları hakkında katılımcılara eğitim vermek ve klinisyenlerin bilerek ya da bilmeyerek hastalarına güvenlik davranışları kullandırtmasının ya da öğretmesinin yaratacağı zararı/riskleri örnekler üzerinden işleyerek katılımcılara fark ettirmek",
            },
            {
                "educationName": "Beck Yönelimli Kognitif Terapinin OKB Tedavisinde Uygulanması Workshop’unda “Nötralizasyonun İntrusif Düşünceler Üzerindeki Etkisi: Obsesif Kompulsif Bozukluğun Etiyolojisini Araştıran Bir Deney” araştırmasını baz alarak ve ilgili diğer makalelerle entegre ederek katılımcılara; nötralizasyonun patolojiyi devam ettiren bir faktör olduğunun eğitimini vermek",
            },
            {
                "educationName": "OKB’de ERP (Maruz Bırakarak Tepki Önleme)’nin Uygulanması Workshop’unun içeriğinde yer alan “Kompulsiyon ve Nötralizasyon” bölümünü workshop katılımcılarına eğitim olarak vermek ve katılımcıların aktif katılımını sağlamak için örnekler üzerinden giderek kompulsiyonların ve nötralizasyonların farklılıklarını pekiştirmek",
            }
        ]
    }
}