import { render } from '@testing-library/react'
import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { WindowSizeContext } from '../../App'
import Header from '../../Components/Header'
import MainContainer from '../../Components/MainContainer'
import { workingAreas } from '../../Constants/WorkingAreas'
import AccordionItem from '../Home/Components/AccordionItem'
import SSS from '../Home/Components/SSS'

export default function ProjectAreasScreen() {
    const isBigScreen = useContext(WindowSizeContext);

    const renderItems = () => {
        return workingAreas.map((element, index) => {
            return <AccordionItem
                icon="icon_working_areas.png"
                titleMode="center"
                title={element.title}
                description={element.description} />
        })
    }

    return (
        <>
            <Header />
            <div style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 30,
                marginTop: 30
            }}>
                Çalışma Alanlarım
            </div>
            <div style={{
                marginTop: 30,
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                alignItems: 'center',
            }}>
                <div style={{
                    width: isMobile && !isBigScreen ? '80%' : '80%',
                    height: '100%'
                }}>
                    {renderItems()}
                </div>
            </div>

        </>
    )
}
