import React from 'react'
import MainContainer from '../../../Components/MainContainer'
import ShowMoreButton from './ShowMoreButton'
import parse from 'html-react-parser';
import { isMobile } from 'react-device-detect';

export default function BlogMobile(props) {
    const { index, title, content, image, id } = props

    const renderContent = () => {
        var textArray = content[0].split(" ")
        textArray = textArray.slice(0, 30)
        return parse(textArray.join(" ") + "...")

    }


    const renderBlogContent = () => {
        return (
            <>
                <div style={{
                    // flex: 0.2,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div id='blogImage' style={{
                        width: 200,
                        height: 200,
                        // backgroundColor: 'red',
                        borderRadius: 10,
                        boxShadow: "-5px 5px 12px rgba(0,0,0,0.2)",
                        overflow: 'hidden'
                    }}>
                        <img style={{
                            width: '100%', height: '100%'
                        }} src={image} />
                    </div>
                </div>
                <div style={{
                    marginTop: 30
                    // marginLeft: 20,
                }}>
                    <div style={{
                        fontSize: 20,
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}>{title}</div>
                    <div id='blogMobileContent' style={{
                        marginTop: 10,
                        lineHeight: '1.7em'
                    }}>{renderContent()} </div>

                </div>
                <ShowMoreButton mobile blogId={id} title={title} />
            </>
        )
    }

    return (

        <MainContainer containerStyle={
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 30,
                width: '100%'
            }
        } style={{
            width: '100%',
            marginRight: isMobile ? 30 : 50,
            marginLeft: isMobile ? 30 : 50,
            maxWidth: 800,
            padding: 30,

        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                {renderBlogContent()}
            </div>
        </MainContainer>
    )
}
