import React, { useEffect, useRef, useState } from 'react'
import NavbarResponsiveContent from './NavbarResponsiveContent'
import NavbarResponsiveItem from './NavbarResponsiveItem'

const MenuNames = ["Anasayfa", "Hakkımda", "Çalışma Alanları", "Blog", "İletişim"]

export default function NavbarResponsive(props) {
    const wrapper = useRef()
    const wrapperMenu = useRef()
    const { routes } = props

    const toggleMenu = () => {
        wrapper.current.classList.toggle('open')
        wrapperMenu.current.classList.toggle('is-nav-open')
        // setMenuOpen(!menuOpen)

    }

    const renderMenuItems = (deneme) => {
        return MenuNames.map((element, index) => {
            return (
                <>
                    <NavbarResponsiveItem routes={routes[index]} title={element} />
                    {
                        index !== MenuNames.length - 1 ?
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',


                            }} >
                                <div className='footerHr' style={{
                                    width: '40%',
                                    height: 1,
                                    // backgroundColor: 'rgba(0,0,0,0.5)',
                                }}>

                                </div>
                            </div> : null
                    }
                </>
            )

        })
    }

    return (
        <>
            <div ref={wrapper} className={'side-bar'} onClick={() => toggleMenu()}>
                <span />
                <span />
                <span />
            </div>

            <div ref={wrapperMenu} className='navbarResponsiveMenuItemsContainer'>
                <div className='navbarBackgroundEffect' />
                <div style={{
                    width: '100%',
                    height: 175,
                    display: 'flex',
                    // alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        marginTop: 25,
                        boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
                    }}>
                        <img src={require(`../Assets/Images/logo4.png`)}
                            style={{ width: 220 }} />
                    </div>
                </div>
                {
                    renderMenuItems()
                }

            </div>
        </>
    )
}
