import React from 'react'

export default function PsychologyTypesMobile(props) {
    const { title, icon, description } = props

    return (
        <div style={{
            width: '80%',
            height: '100%',
            // minWidth: 200,
            // height: 400,
            paddingTop: 50,
            paddingBottom: 50,
            // backgroundColor: 'red',
            borderRadius: 10,
            margin: '0px 0px 30px 0px',
            boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
            backgroundColor:'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // paddingTop:20

        }}>
            <div style={{
                width: 100,
                height: 100,
                borderRadius: 50,
                boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img
                    style={{
                        objectFit: "cover",
                        width: '60%',
                        height: '60%',
                    }} src={require(`../../../Assets/Images/${icon}`)} />
            </div>
            <div style={{
                marginTop: 20,
                fontWeight: 'bold',
                fontSize: 28,
                textAlign: 'center',


            }}>
                {title}
            </div>
            <div style={{
                fontSize: 18,
                lineHeight: 1.3,
                textAlign: 'center',
                marginTop: 25,
                padding: '0px 5px'
            }}>
                {description}
            </div>

        </div>
    )
}
