import React from 'react'
import Title from './Title'
import './Styles/Navbar.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routeName, routePath } from '../Utils/HeaderUtils';

const MenuNames = ["Anasayfa", "Hakkımda", "Çalışma Alanları", "Blog", "İletişim"]
const MenuRoutes = ["/", "/hakkimda", "/calisma_alanlari", "/blog", "/iletisim"]

export default function Navbar(props) {
    const { routes } = props
    const location = useLocation();
    const navigate = useNavigate()

    // const renderMenu = () => {
    //     return MenuNames.map((element, index) => {
    //         return <>
    //             <a href='#' className='menuItems'>
    //                 <div >
    //                     <Link style={
    //                         routeName(location?.pathname) === element ? {
    //                             color: 'black'
    //                         } : {
    //                             color: 'rgba(0,0,0,0.4)'
    //                         }} to={routes[index]}>{element}</Link>
    //                 </div>
    //                 {
    //                     index !== MenuNames.length - 1 ?
    //                         <div className='seperator' /> : null
    //                 }
    //             </a>
    //         </>
    //     })
    // }

    const renderMenuItems = () => {
        return MenuNames.map((element, index) => {
            return <a
                style={
                    routeName(location?.pathname) === element ? {
                        color: 'black'
                    } : {
                        color: 'rgba(0,0,0,0.4)'
                    }} onClick={() => _onPress(MenuRoutes[index])}>{element}</a>
        })
    }

    const _onPress = (routeName) => {
        navigate(routeName)
    }

    return (
        <div id='navContainer'>
            <Title />
            <div id='itemContainer'>
                {/* {renderMenu()} */}
                <div class="menuItemContainer borderXwidth">
                    {renderMenuItems()}
                </div>
            </div>
        </div>
    )
}
