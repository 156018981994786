import React, { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { WindowSizeContext } from '../../App'
import Header from '../../Components/Header'
import MainContainer from '../../Components/MainContainer'
import { blogs } from '../../Constants/Blogs'
import parse from 'html-react-parser';

export default function BlogDetailScreen() {
    const location = useLocation()
    const [blog, setBlog] = useState([])
    const isBigScreen = useContext(WindowSizeContext);

    useEffect(() => {
        var blogId = location?.pathname.split('id=')[1]
        var blog = blogs.blogs.filter(blog => blog.id == blogId)
        setBlog(blog[0])
    }, [])

    useEffect(() => {

    }, [blog])

    const renderContent = () => {
        if (blog?.id) {
            return parse(blog?.content[0])
        }
    }

    return (
        <>
            <Header />

            <MainContainer containerStyle={
                {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 30,
                    width: '100%'
                }
            } style={{
                width: '100%',
                marginRight: isMobile ? 30 : 50,
                marginLeft: isMobile ? 30 : 50,
                maxWidth: 800,
                padding: isMobile ? 30 : 50,

            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    {/* {renderBlogContent()} */}
                    <div style={{
                        textAlign: 'center',
                        // marginTop: 30,
                        fontSize: 20,
                        fontWeight: 'bold',
                        width: '100%'
                    }}>{blog?.title}</div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 20
                    }}>
                        <div id='blogImage' style={{
                            width: 200,
                            height: 200,
                            // backgroundColor: 'red',
                            borderRadius: 10,
                            boxShadow: "-5px 5px 12px rgba(0,0,0,0.2)",
                            overflow: 'hidden'
                        }}>
                            <img style={{
                                width: '100%', height: '100%'
                            }} src={blog?.image} />
                        </div>
                    </div>

                    <div style={{
                        marginTop: 20,
                        lineHeight: '1.7em'
                    }}>
                        {renderContent()}
                    </div>
                </div>
            </MainContainer>
        </>
    )
}
