import React, { useContext } from 'react'
import { WindowSizeContext } from '../../../App';
import MainContainer from '../../../Components/MainContainer'
import TherapyItemContainer from './TherapyItemContainer';

const types = [
    { title: "Online Terapi", icon: "therapy_online.png" },
    { title: "Yetişkin Terapisi", icon: "therapy_adult.png" },
    { title: "Ergen Terapisi", icon: "students.png" },
    { title: "Çocuk Terapisi", icon: "therapy_child.png" },
]

export default function TherapyTypes() {
    const isBigScreen = useContext(WindowSizeContext);
    console.log('isbi:', isBigScreen)
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 30,
            // backgroundColor:'red'
        }}>
            <MainContainer containerStyle={{
                width: '80%',

            }} style={{ alignItems: null }}>
                <div style={{

                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    marginTop: 20, marginBottom: 10
                }}>
                    <div style={{ fontSize: 27, fontWeight: 'bold' }}>Terapi Hizmetleri</div>
                </div>
                <div style={{
                    // backgroundColor: 'yellow',
                    display: 'flex',
                    flexDirection: isBigScreen ? 'row' : 'column',
                    // alignItems: 'center',
                    margin: 20
                }}>

                    <TherapyItemContainer isBigScreen={isBigScreen} items={types.slice(0, 2)} first={true} />
                    <TherapyItemContainer isBigScreen={isBigScreen} items={types.slice(2, 4)} />

                </div>
            </MainContainer>
        </div>
    )
}
