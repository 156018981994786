import React from 'react'
import MainContainer from '../../../Components/MainContainer'

export default function EducationItem(props) {
    const { time, teachers, content, name, giving } = props

    const renderContent = () => {
        if (content) {
            return content.map((item) => {
                return <p>- {item}</p>
            })
        }
    }

    const renderTeachers = () => {
        if (teachers) {
            let teacherList = ""
            teachers?.map((item, index) => {
                if (index !== teachers.length - 1) teacherList += item + ", "
                else teacherList += item
            })
            return <p>
                <span style={{
                    fontWeight: 'bold'
                }}>{teachers.length > 1 ? "Eğitmenler: " : "Eğitmen: "}</span> {teacherList}
            </p>
        }
    }

    return (
        <MainContainer id="aboutLessons" containerStyle={{
            width: '100%'
        }} style={{
            width: '100%',
            marginTop: 20,
            overflow: 'hidden',
            alignItems: null
        }}>

            <div style={{
                padding: '0px 5px 0px 15px',
            }}>
                {
                    !giving ?
                        <>
                            <p style={{
                                fontWeight: 'bold',
                                fontSize: 17,
                            }}>{name}</p>
                            <p><span style={{
                                fontWeight: 'bold',
                                fontSize: 17,
                            }}>Süre:</span> {time}</p>
                            {renderTeachers()}
                            <p style={{
                                display: content.length > 1 ? null : 'none',
                                fontWeight: 'bold'
                            }}>Eğitimin İçeriği</p>
                            {renderContent()}</>
                        :
                        <p style={{
                            // fontWeight: 'bold',
                            // fontSize: 17,
                        }}>- {name}</p>
                }
            </div>
        </MainContainer>
    )
}

EducationItem.defaultProps = {
    giving: false
}