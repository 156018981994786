import React, { useEffect, useRef, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import Avatar from './Avatar'
import Navbar from './Navbar'
import NavbarResponsive from './NavbarResponsive'
import './Styles/Header.css'
import './Styles/Navbar.css'
import Title from './Title'

const MenuRoutes = ["/", "/hakkimda", "/calisma_alanlari", "/blog", "/iletisim"]

export default function Header(props) {
    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 756)
    const wrapperMenu = useRef()
    const wrapper = useRef()

    useEffect(() => {
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const handleMediaQueryChange = (matches) => {
        setIsBigScreen(window.innerWidth > 756)
    }

    const toggleMenu = () => {
        // setMenuOpen(!menuOpen)
        wrapper.current.classList.toggle('open')
        wrapperMenu.current.classList.toggle('is-nav-open')

    }

    return (
        <>
            <div className='headerContainer'>
                <img
                    id='backgroundImage'
                    src={require('../Assets/Images/background2.jpg')} />

                {
                    !isMobile && !isBigScreen ?
                        <div id='navContainer'>
                            <Title />
                        </div> : null
                }
                {
                    isBigScreen ?
                        <Navbar routes={MenuRoutes} /> : <NavbarResponsive toggleMenu={toggleMenu} routes={MenuRoutes} wrapper={wrapper} />
                }
                {
                    isMobile && !isBigScreen ?
                        <div style={{
                            position: 'absolute',
                            top: '5%'
                        }}>
                            <Title />
                        </div> : null
                }

                <Avatar />

            </div>
        </>
    )
}
