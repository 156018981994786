import React, { useEffect, useState } from 'react'
import { MenuNames, MenuRoutes } from '../Constants/ConstantValues'
import FooterContact from './FooterContact'
import FooterMenuItem from './FooterMenuItem'
import FooterResponsive from './FooterResponsive'
import './Styles/Footer.css'

const menu = ["Anasayfa",
    "Hakkımda",
    "Çalışma Alanları",
    "Blog",
    "İletişim"]

export default function Footer() {
    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth > 756)

    useEffect(() => {
        window.addEventListener('resize', handleMediaQueryChange)
    }, [])

    const handleMediaQueryChange = (matches) => {
        setIsBigScreen(window.innerWidth > 756)
    }

    const renderMenuItems = () => {
        return MenuNames.map((element, index) => {
            return <FooterMenuItem route={MenuRoutes[index]} index={index} title={element} />
        })
    }

    return isBigScreen ? (
        <><div className='footerContainer'>

            <div className='footerLogoContainer'>
                <div className='footerImageContainer'>
                    <div style={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        marginTop: 25,
                        boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
                    }}>

                        <img src={require(`./../Assets/Images/logo4.png`)}
                            className='footerImage' />
                    </div>
                </div>
            </div>


            <div id='footerMenuContainer'>
                {renderMenuItems()}
            </div>

            <FooterContact />

        </div>
            <div style={{
                width: '100%',
                height: 50,
                backgroundColor: 'rgba(255,255,255,0.15)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 14
            }}>
                @2022 Zeynep Ekşioğlu - Tüm Hakları Saklıdır.
            </div>
        </>
    ) : <FooterResponsive />

}
