import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";

export default function MoveToTop() {

    const [scroll, setScroll] = useState(0)
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = (e) => {
        let scrollPosition = window.scrollY
        if (scrollPosition !== 0 && scroll === 0) {
            setScroll(scrollPosition)
        } else if (scroll !== 0 && scrollPosition === 0) {
            setScroll(0)
        }
    }

    const moveToTopClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return scroll ? (
        <div
            onClick={() => moveToTopClick()} style={{
                width: 50,
                height: 50,
                borderRadius: '100%',
                backgroundColor: 'rgba(255,255,255,0.7)',
                position: 'fixed',
                bottom: 10,
                right: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: "0px 0px 12px rgba(0,0,0,0.2)",
                cursor: 'pointer'
            }}>
            <img
                style={{
                    width: '70%', height: '70%',
                    marginTop: -3
                }}
                src={require('../Assets/Images/arrow_top.png')} />

        </div>
    ) : null
}
