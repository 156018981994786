import React from 'react'
import MainContainer from '../../../Components/MainContainer'
import { educations } from '../../../Constants/TextFiles'
import EducationItem from './EducationItem'

export default function TakingEducations() {

    const renderGivingEducations = () => {
        return educations.educations.givingEducations.map((element) => {
            return <EducationItem
                giving={true}
                teachers={element?.teachers}
                time={element?.time}
                content={element?.content}
                name={element?.educationName}
            />
        })
    }

    return (
        <>
            <div style={{
                marginTop: 30,
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 27,
                // marginTop: 20
            }}>
                Verdiğim Eğitimler

            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
                width: '100%',
                // backgroundColor:'red'
            }}>
                {renderGivingEducations()}
            </div>
        </>
    )
}
