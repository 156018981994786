import React from 'react'
import './Styles/PsychologyStyles.css'
import { isMobile, isTablet } from 'react-device-detect'

export default function PsychologyTypes(props) {
    const { title, icon, description } = props
    return (
        <div className='psyMainContainer'>
            <div className={!isMobile && !isTablet ? 'psyImageContainer' : 'psyImageContainerMobile'}>
                <img
                    style={{
                        objectFit: "cover",
                        width: '60%',
                        height: '60%',
                    }} src={require(`../../../Assets/Images/${icon}`)} />
            </div>
            <div className='psyTitle'>
                {title}
            </div>
            <div className='psyDescription'>
                {description}
            </div>

        </div>
    )
}
