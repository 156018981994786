export const routeName = (routePath) => {
    switch (routePath) {
        case "/":
            return "Anasayfa"
        case "/hakkimda":
            return "Hakkımda"
        case "/calisma_alanlari":
            return "Çalışma Alanları"
        case "/blog":
            return "Blog"
        case "/iletisim":
            return "İletişim"
        default:
            break;
    }
}